export class TypeScreen {
    Id: number;
    NameToManage: string;
    NameProject: string;
    Tags: string;
    SettingsScreen: string;
    SettingsManage: string;
    VersionScreen: string;
    Deleted: boolean;
    SettingsManageAction:SettingsManageAction;

    constructor() {
        this.Id = null;
        this.NameToManage = '';
        this.NameProject = '';
        this.Tags = '';
        this.SettingsScreen = '';
        this.SettingsManage = null;
        this.VersionScreen = '';
        this.Deleted = false;
        this.SettingsManageAction = null;
    }
}
export class SettingsManage{
    Manager:SettingsManageAction;
    User:SettingsManageAction;
    constructor() {
        this.Manager = new SettingsManageAction();
        this.User = new SettingsManageAction();
    }
}
export class SettingsManageAction{
    Disabled:SettingsManageProp;
    Show:SettingsManageProp;
    Must:SettingsManageProp;
    Used:SettingsManageProp;
    constructor() {
        this.Disabled = new SettingsManageProp();//disabled
        this.Show = new SettingsManageProp();//ngIf
        this.Must = new SettingsManageProp();//required
        this.Used = new SettingsManageProp();//border-color: #0e7ddc;
        //placeHolder
        
    }
}
export class SettingsManageProp{
    FloorNumber: boolean;
    FloorName: boolean;
    TextSize: boolean;
    BuildingId: boolean;
    Deleted: boolean;
    TextSizeMainInFloor: boolean;
    TextSizeMainInLobby: boolean;
    TextSizeSecondInFloor: boolean;
    TextSizeSecondInLobby: boolean;
    HeightCompaniesInPercentToFloor: boolean;
    HeightCompaniesInPercentToLobby: boolean;
    TextSizeFloorNameInFloor: boolean;
    TextSizeTimeInFloor: boolean;
    HeightLogoSivInFloor: boolean;
    HeightSivMainInFloor: boolean;
    FloorNameToFloor: boolean;
    FloorNameToLobby: boolean;
    HeightAllCompaniesIntoSivMainInFloor: boolean;
    TimeBetweenPages: boolean;
    EveryMillisecondGoToServer: boolean;
    UrlScreen: boolean;
    SettingsScreen: boolean;
    SettingsManage: boolean;
    Comments: boolean;
    TypeScreenId: boolean;
    TypeScreen: boolean;
    RemoteControl:boolean;
    constructor() {
        this.FloorNumber= true;
        this.FloorName= true;
        this.TextSize= true;
        this.BuildingId= true;
        this.Deleted= true;
        this.TextSizeMainInFloor= true;
        this.TextSizeMainInLobby= true;
        this.TextSizeSecondInFloor= true;
        this.TextSizeSecondInLobby= true;
        this.HeightCompaniesInPercentToFloor= true;
        this.HeightCompaniesInPercentToLobby= true;
        this.TextSizeFloorNameInFloor= true;
        this.TextSizeTimeInFloor= true;
        this.HeightLogoSivInFloor= true;
        this.HeightSivMainInFloor= true;
        this.FloorNameToFloor= true;
        this.FloorNameToLobby= true;
        this.HeightAllCompaniesIntoSivMainInFloor= true;
        this.TimeBetweenPages= true;
        this.EveryMillisecondGoToServer= true;
        this.UrlScreen= true;
        this.SettingsScreen= true;
        this.SettingsManage= true;
        this.Comments= true;
        this.TypeScreenId= true;
        this.TypeScreen= true;
        this.RemoteControl= true;
    }
}

