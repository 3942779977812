import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Floor } from '../model/floor-class.model';
import { MainDataService } from './main-data.service';

@Injectable({
  providedIn: 'root'
})
export class FloorService {
  buildingId: any = 0;
  constructor(private apiService: ApiService, private mainData: MainDataService) {
    //this.buildingId= mainData.currentBuilding.Id;
    this.buildingId = localStorage.getItem("BuildingID");
  }
  getFloorList(AllBuilding: boolean = false) {
    this.buildingId = localStorage.getItem("BuildingID");
    if (this.buildingId && this.buildingId != -1) {
      if (AllBuilding)
        return this.apiService.get(this.apiService.rootUrlScreen + 'GetFloors');
      else
        return this.apiService.get(this.apiService.rootUrlScreen + 'GetFloors?buildingId=' + this.buildingId);
    }
  }
  getFloorById(id) {
    return this.apiService.get(this.apiService.rootUrlScreen + 'GetFloorByFloorId/' + id);
  }
  addFloor(floor: Floor) {
    if (!floor.BuildingId || floor.BuildingId < 0) {
      this.buildingId = localStorage.getItem("BuildingID");
      floor.BuildingId = this.buildingId;
    }
    return this.apiService.post(this.apiService.rootUrlManagementSystem + 'AddFloor', floor)
  }
  updateFloor(floor: Floor) {
    return this.apiService.post(this.apiService.rootUrlManagementSystem + 'EditFloor', floor)
  }
  deleteFloor(id) {
    return this.apiService.get(this.apiService.rootUrlManagementSystem + 'RemoveFloor?id=' + id);
  }
  updateLastUpdateTimeByFloor(id: any) {
    return this.apiService.get(this.apiService.rootUrlManagementSystem + 'LastUpdateTimeByFloor?floorId=' + id);
  }
  updateLastUpdateTimeByBuilding() {
    this.buildingId = localStorage.getItem("BuildingID");
    return this.apiService.get(this.apiService.rootUrlManagementSystem + 'LastUpdateTimeByBuilding?buildingId=' + this.buildingId);
  }
  updateLastUpdateTimeRefreshByFloor(id: any) {
    return this.apiService.get(this.apiService.rootUrlManagementSystem + 'LastUpdateTimeRefreshByFloor?floorId=' + id);
  }
  updateLastUpdateTimeRefreshByBuilding() {
    this.buildingId = localStorage.getItem("BuildingID");
    return this.apiService.get(this.apiService.rootUrlManagementSystem + 'LastUpdateTimeRefreshByBuilding?buildingId=' + this.buildingId);
  }
  GetLastOnlineScreenTimeByBuilding(AllBuilding: boolean = false) {
    if (AllBuilding)
      return this.apiService.get(this.apiService.rootUrlManagementSystem + 'GetLastUpdateTimeOnOnlineScreen');
    else {
      this.buildingId = localStorage.getItem("BuildingID");
      return this.apiService.get(this.apiService.rootUrlManagementSystem + 'GetLastUpdateTimeOnOnlineScreen?buildingId=' + this.buildingId);
    }
  }

  updateLastUpdateTimeAppScreenByFloor(id: any) {
    return this.apiService.get(this.apiService.rootUrlManagementSystem + 'LastUpdateTimeAppScreen?floorId=' + id);
  }
  updateLastUpdateTimeAppScreenByBuilding() {
    this.buildingId = localStorage.getItem("BuildingID");
    return this.apiService.get(this.apiService.rootUrlManagementSystem + 'LastUpdateTimeAppScreenByBuilding?buildingId=' + this.buildingId);
  }

  updateRefreshAppScreenByFloor(id: any) {
    return this.apiService.get(this.apiService.rootUrlManagementSystem + 'RefreshAppScreenByFloor?floorId=' + id);
  }
  updateRefreshAppScreenByBuilding() {
    this.buildingId = localStorage.getItem("BuildingID");
    return this.apiService.get(this.apiService.rootUrlManagementSystem + 'RefreshAppScreenByBuilding?buildingId=' + this.buildingId);
  }

  updateRestartDeviceInAppScreenByFloor(id: any) {
    return this.apiService.get(this.apiService.rootUrlManagementSystem + 'RestartDeviceInAppScreenByFloor?floorId=' + id);
  }
  updateRestartDeviceInAppScreenByBuilding() {
    this.buildingId = localStorage.getItem("BuildingID");
    return this.apiService.get(this.apiService.rootUrlManagementSystem + 'RestartDeviceInAppScreenByBuilding?buildingId=' + this.buildingId);
  }

  updateShutDownDeviceInAppScreenByFloor(id: any) {
    return this.apiService.get(this.apiService.rootUrlManagementSystem + 'ShutDownDeviceInAppScreenByFloor?floorId=' + id);
  }
  updateShutDownDeviceInAppScreenByBuilding() {
    this.buildingId = localStorage.getItem("BuildingID");
    return this.apiService.get(this.apiService.rootUrlManagementSystem + 'ShutDownDeviceInAppScreenByBuilding?buildingId=' + this.buildingId);
  }
}
