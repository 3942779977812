import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { MainDataService } from './main-data.service';
import { TypeScreen } from '../model/typeScreen-class.model';

@Injectable({
  providedIn: 'root'
})
export class TypeScreenService {
  constructor(private apiService: ApiService) {
  }
  getTypeScreenList() {
    return this.apiService.get(this.apiService.rootUrlScreen + 'GetTypeScreens');
  }
  getTypeScreenById(id: number) {
    return this.apiService.get(this.apiService.rootUrlScreen + 'GetTypeScreen/' + id);
  }
  updateTypeScreen(typeScreen: TypeScreen) {
    return this.apiService.post(this.apiService.rootUrlManagementSystem + 'EditTypeScreen', typeScreen);
  }
  addTypeScreen(typeScreen: TypeScreen) {
    return this.apiService.post(this.apiService.rootUrlManagementSystem + 'AddTypeScreen', typeScreen);
  }
  removeTypeScreen(id: number) {
    return this.apiService.get(this.apiService.rootUrlManagementSystem + 'RemoveTypeScreen/' + id);
  }
}